import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

type ColumnProps = {
  width: number;
  noMarginLeft?: boolean;
  noMarginRight?: boolean;
};

const Column = styled.div<ColumnProps>`
  width: ${({ width }) => width}%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-right: ${({ noMarginLeft }) => (noMarginLeft ? 0 : getSpacing(4))};
  margin-left: ${({ noMarginRight }) => (noMarginRight ? 0 : getSpacing(4))};
`;
export default Column;
