import { gql } from '@apollo/client';

export const deleteBusinessUnit = gql`
  mutation deleteBusinessUnit($id: String!, $companyId: String!) {
    deleteBusinessUnit(id: $id, companyId: $companyId) {
      id
      name
    }
  }
`;
