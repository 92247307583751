import { gql } from '@apollo/client';

export const deleteBusinessUnitAdmin = gql`
  mutation deleteBusinessUnitAdmin($id: String!) {
    deleteBusinessUnitAdmin(id: $id) {
      id
      email
    }
  }
`;
