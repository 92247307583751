import React from 'react';
import { Dot, Wrapper, LoaderWrapper } from './styles';

export const Loader: React.FC = () => {
  return (
    <LoaderWrapper>
      <Wrapper>
        <Dot />
        <Dot />
        <Dot />
        <Dot />
      </Wrapper>
    </LoaderWrapper>
  );
};
