import { gql } from '@apollo/client';

export const getApplicationAttributes = gql`
  query getApplicationAttributes {
    getApplicationAttributes {
      companyCategories {
        id
        name
      }
      companyLabels {
        id
        name
      }
    }
  }
`;
