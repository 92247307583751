import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: ${getSpacing(8)};
  padding-right: ${getSpacing(8)};
  height: 64px;
  background-color: ${({ theme }) => theme.colors.deepBlue};
`;

export const StyledLink = styled.a<{ isActive: boolean }>`
  margin-right: ${getSpacing(8)};
  font-size: 15px;
  color: ${({ theme, isActive }) => (isActive ? theme.colors.white : theme.colors.darkGrey)};
  font-weight: 500;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
`;

export const LogoLink = styled.a`
  display: flex;
`;
