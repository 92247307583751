import React from 'react';
import { Styles } from './styles';
import Navbar from 'components/Navbar';

export const AdminWrapper: React.FC = ({ children }) => {
  return (
    <Styles.Wrapper>
      <Navbar />
      <Styles.Content>{children}</Styles.Content>
    </Styles.Wrapper>
  );
};
