import { gql } from '@apollo/client';

export const getNewsFeedItems = gql`
  query getNewsFeedItems($companyId: String, $areasOfPracticeIds: [String!], $skip: Int) {
    getNewsFeedItems(companyId: $companyId, areasOfPracticeIds: $areasOfPracticeIds, skip: $skip) {
      id
      title
      url
      imageUrl
      datePublished
      companyName
      companyId
      body
      areasOfSpecialisationIds
      areasOfPracticeIds
    }
  }
`;
