import styled from 'styled-components';
import { defaultTheme } from 'constants/theme/defaultTheme';

export const Styles = {
  Wrapper: styled.div`
    height: 100vh;
    background-color: ${defaultTheme.colors.snowGrey};
  `,
  Content: styled.div`
    max-width: ${({ theme }) => theme.maxContentWidth}px;
    padding: ${defaultTheme.spacing(8)};
    margin: 0 auto;
  `,
};
