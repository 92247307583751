import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

type RowProps = {
  selectable?: boolean;
};

const Row = styled.div<RowProps>`
  display: flex;
  padding: ${getSpacing(4)};
  border: 1px solid transparent;
  align-items: center;

  :nth-child(odd) {
    background: ${({ theme }) => theme.colors.snowGrey};
  }

  ${props =>
    props.selectable &&
    `
    &:hover {
      background: rgba(124,139,152,0.1);
      cursor:pointer;
    }
  `}
`;
export default Row;
