import styled from 'styled-components';
import { defaultTheme } from 'constants/theme/defaultTheme';
import { getSpacing } from 'stylesheet';

export const Wrapper = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${getSpacing(2)} 0;
  ${props =>
    props.isDisabled &&
    `
    opacity: 0.5;
  `}
`;

export const Title = styled.p<{ color?: string }>`
  color: ${props => (props.color ? props.color : defaultTheme.colors.healthBlue)};
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin-left: ${getSpacing(1)};
`;
