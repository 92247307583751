import React from 'react';

type LogoProps = {
  maxWidth?: number;
};

const Logo: React.FC<LogoProps> = ({ maxWidth }) => (
  <svg
    data-testid="logo"
    width={maxWidth || '59'}
    height={maxWidth || '24'}
    viewBox="0 0 59 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M31.5547 1.99333C31.4627 1.83863 31.2734 1.65222 30.8923 1.65222H16.221C15.8392 1.65222 15.6498 1.83786 15.5579 1.99333C15.4388 2.19599 15.3809 2.50152 15.3809 2.92926V3.5527C15.3809 3.98044 15.4388 4.28675 15.5579 4.48785C15.6491 4.64255 15.8384 4.82896 16.221 4.82896H21.7669V22.9102C21.7669 23.529 22.301 23.8438 23.3538 23.8438H23.8214C24.8749 23.8438 25.409 23.5298 25.409 22.9102V4.82896H30.8923C31.2741 4.82896 31.4635 4.6441 31.5547 4.48785C31.6745 4.2852 31.7317 3.97967 31.7317 3.5527V2.92926C31.7317 2.50229 31.6745 2.19676 31.5547 1.99333Z"
        fill="white"
      />
      <path
        d="M42.1642 21.7576C42.0421 21.1473 41.9803 20.1402 41.9803 18.7642V12.0936C41.9803 10.494 41.4214 9.22158 40.32 8.31118C39.2186 7.40077 37.6147 6.95137 35.5332 6.95137C34.5512 6.94579 33.572 7.05666 32.6161 7.28166C31.7017 7.5021 30.9542 7.78056 30.3938 8.10852C29.7863 8.46433 29.4918 8.80467 29.4918 9.16202C29.4918 9.44357 29.6464 9.83187 29.9664 10.3493C30.305 10.8993 30.5994 11.1553 30.8939 11.1553C30.9612 11.1553 31.0756 11.129 31.7249 10.8127C32.2414 10.5683 32.7755 10.3627 33.3225 10.1977C33.9409 10.0059 34.6721 9.90844 35.5038 9.90844C36.5604 9.90844 37.3333 10.1157 37.8002 10.5272C38.2671 10.9387 38.4959 11.4694 38.4959 12.1562V13.3435C35.4242 13.3776 32.9917 13.8177 31.2634 14.6515C29.4694 15.5171 28.5581 17.0493 28.5581 19.2012C28.5581 20.7381 29.0366 21.9363 29.9811 22.7593C30.9194 23.5807 32.211 23.9969 33.8211 23.9969C34.9635 23.9969 35.9784 23.7602 36.8356 23.2922C37.5555 22.907 38.2082 22.4073 38.7679 21.8125C38.7903 21.9262 38.8174 22.0624 38.8506 22.2209C38.9219 22.5737 39.0294 22.9182 39.1714 23.2489C39.2356 23.419 39.3515 23.5646 39.5028 23.6652C39.6541 23.7659 39.8331 23.8164 40.0147 23.8097C40.5052 23.8039 40.9932 23.7398 41.4686 23.6186C42.0529 23.4748 42.3498 23.2149 42.3498 22.8451C42.3544 22.7825 42.3327 22.5969 42.1642 21.7576ZM38.4935 16.1111V19.0133C37.9713 19.5942 37.3565 20.0846 36.674 20.4643C36.0155 20.824 35.2333 21.0058 34.3498 21.0058C33.6464 21.0058 33.1007 20.8263 32.7266 20.4721C32.3525 20.1178 32.167 19.6065 32.167 18.9475C32.167 17.7873 32.7235 17.0439 33.8675 16.6788C35.0114 16.3138 36.5759 16.1235 38.4935 16.1142V16.1111Z"
        fill="white"
      />
      <path
        d="M46.7779 7.10693H46.4687C45.4159 7.10693 44.8818 7.42175 44.8818 8.04132V22.9102C44.8818 23.529 45.4159 23.8438 46.4687 23.8438H46.7779C47.8314 23.8438 48.3655 23.5298 48.3655 22.9102V8.04132C48.3655 7.42175 47.8314 7.10693 46.7779 7.10693Z"
        fill="white"
      />
      <path
        d="M46.6534 0.904175C45.4345 0.904175 44.8169 1.24993 44.8169 1.93138V3.77075C44.8169 4.4522 45.4136 4.79795 46.5947 4.79795C47.8128 4.79795 48.4312 4.4522 48.4312 3.77075V1.93138C48.4281 1.24838 47.8306 0.904175 46.6534 0.904175Z"
        fill="white"
      />
      <path
        d="M57.9524 21.6625C57.7816 21.1396 57.5605 20.8851 57.2753 20.8851C57.109 20.893 56.9442 20.9208 56.7845 20.9679C56.5566 21.0218 56.3229 21.0465 56.0888 21.0414C55.6529 21.0414 55.3236 20.9099 55.084 20.6407C54.8444 20.3715 54.7184 19.8757 54.7184 19.1711V0.933609C54.7184 0.314812 54.1843 0 53.1315 0H52.7884C51.5022 0 51.2324 0.508186 51.2324 0.933609V19.3258C51.2324 20.9857 51.6591 22.1947 52.5008 22.9187C53.3426 23.6426 54.3559 24.0016 55.53 24.0016C57.2861 24.0016 58.1766 23.5723 58.1766 22.7245C58.1781 22.4778 58.1039 22.1328 57.9524 21.6625Z"
        fill="white"
      />
      <path
        d="M16.7805 7.62908C15.1867 7.61825 14.5096 7.62908 14.5096 7.62908C14.5096 7.62908 13.2497 7.79151 12.5865 9.25883C12.2124 10.0857 10.4501 14.0065 8.90186 17.4501L11.3985 23.0054L11.4039 23.0139C11.5959 22.7948 11.7524 22.5468 11.8676 22.2791C12.5223 20.7986 17.6493 9.09949 17.6493 9.09949C17.6493 9.09949 18.3449 7.63914 16.7805 7.62908Z"
        fill="#3BE5FF"
      />
      <path
        d="M8.90209 17.4478L6.40625 23.0169C6.79884 23.4645 7.34384 23.7499 7.93513 23.8175C7.93513 23.8175 8.32161 23.8252 9.66962 23.8175C10.6528 23.8121 11.3987 23.003 11.3987 23.003L11.3856 22.9736L8.90209 17.4478Z"
        fill="white"
      />
      <path
        d="M6.3953 23.0092C6.37726 22.9891 6.35948 22.9682 6.34196 22.9466C6.29636 22.8916 6.2523 22.8336 6.20902 22.771C6.10112 22.6156 6.00869 22.4501 5.93308 22.2767C5.28226 20.7986 0.152997 9.09945 0.152997 9.09945C0.152997 9.09945 -0.542653 7.63909 1.02333 7.62981C2.61792 7.61898 3.29424 7.62981 3.29424 7.62981C3.29424 7.62981 4.55414 7.79225 5.21733 9.25957L8.90196 17.4509L6.40612 23.02L6.3953 23.0092Z"
        fill="#009EFF"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="58.1772" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Logo;
