import { gql } from '@apollo/client';

export const archiveNewsFeedItem = gql`
  mutation archiveNewsFeedItem($newsFeedItemId: String!) {
    archiveNewsFeedItem(newsFeedItemId: $newsFeedItemId) {
      title
      id
      companyName
      companyId
    }
  }
`;
