import { gql } from '@apollo/client';

export const deleteCompany = gql`
  mutation deleteCompany($companyId: String!) {
    deleteCompany(companyId: $companyId) {
      id
      name
    }
  }
`;
