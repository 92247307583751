import React from 'react';
import { Title, Wrapper } from './styles';

type TextButtonProps = {
  title: string;
  onClick: () => void;
  color?: string;
  testId?: string;
  disabled?: boolean;
  noSvg?: boolean;
};

export const TextButton: React.FC<TextButtonProps> = ({
  title,
  onClick,
  color,
  testId,
  disabled,
  noSvg,
}) => {
  return (
    <Wrapper isDisabled={disabled} onClick={onClick} data-testid={testId}>
      {!noSvg && (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 3.75024V14.2502"
            stroke="#009EFF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.75 9H14.25"
            stroke="#009EFF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
      <Title color={color}>{title}</Title>
    </Wrapper>
  );
};
