import { gql } from '@apollo/client';

export const archiveEmployee = gql`
  mutation archiveEmployee($employeeId: String!) {
    archiveEmployee(employeeId: $employeeId) {
      id
      firstName
      lastName
    }
  }
`;
