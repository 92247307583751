import { OperationVariables, useMutation, useQuery } from '@apollo/client';
import { BUAdminQueryType } from 'components/CompanyDetails/CompanyDetails.model';
import { archiveEmployee } from 'mutations/archiveEmployee';
import { deleteBusinessUnit } from 'mutations/deleteBusinessUnit';
import { deleteBusinessUnitAdmin } from 'mutations/deleteBusinessUnitAdmin';
import { deleteCompany } from 'mutations/deleteCompany';
import { getBusinessUnitAdmins } from 'queries/company';
import { deleteClinician } from 'mutations/deleteClinician';
import { getNewsFeedItems } from 'queries/getNewsFeedItems';
import { Mutation, NewsFeedItem, Query } from '../../../../types/graphql';
import { archiveNewsFeedItem } from 'mutations/archiveNewsFeedItem';
import { getClinicalAttributes } from 'queries/clinicalAttributes';
import { getApplicationAttributes } from 'queries/getApplicationAttributes';

export const useGetBusinessUnitAdmins = (options: OperationVariables) => {
  return useQuery<BUAdminQueryType>(getBusinessUnitAdmins, options);
};

export const useDeleteBusinessUnitAdmin = (options?: OperationVariables) => {
  return useMutation(deleteBusinessUnitAdmin, options);
};

export const useDeleteClinician = (options?: OperationVariables) => {
  return useMutation(deleteClinician, options);
};

export const useArchiveEmployee = (options?: OperationVariables) => {
  return useMutation(archiveEmployee, options);
};

export const useDeleteBusinessUnit = (options?: OperationVariables) => {
  return useMutation(deleteBusinessUnit, options);
};

export const useDeleteCompany = (options?: OperationVariables) => {
  return useMutation(deleteCompany, options);
};

export const useGetNewsFeedItems = (options?: OperationVariables) => {
  return useQuery<{ getNewsFeedItems: NewsFeedItem[] }>(getNewsFeedItems, options);
};

export const useArchiveNewsFeedItem = (options?: OperationVariables) => {
  return useMutation<Pick<Mutation, 'archiveNewsFeedItem'>>(archiveNewsFeedItem, options);
};

export const useGetClinicalAttributes = (options?: OperationVariables) => {
  return useQuery(getClinicalAttributes, options);
};

export const useGetApplicationAttributes = (options?: OperationVariables) => {
  return useQuery<Pick<Query, 'getApplicationAttributes'>>(getApplicationAttributes, options);
};
