import { gql } from '@apollo/client';

export const getCompany = gql`
  query getCompany($companyId: String!) {
    getCompany(companyId: $companyId) {
      id
      description
      name
      areasOfSpecialisationIds
      areasOfPracticeIds
      weighting
      logoUrl
    }
  }
`;
