import { gql } from '@apollo/client';

export const getCompanyAndBusinessUnit = gql`
  query getCompanyAndBusinessUnit($companyId: String!) {
    getCompany(companyId: $companyId) {
      id
      name
      businessUnitAdminsNumber
      businessUnitsNumber
      description
      employeesNumber
      logoUrl
      areasOfSpecialisationIds
      areasOfPracticeIds
      weighting
      contactUrl
      shortDescription
      heroImageUrl
      label {
        id
        name
      }
      featured
      categories {
        id
        name
      }
      website
    }
    getBusinessUnits(companyId: $companyId) {
      id
      companyId
      companyName
      name
      employeesNumber
    }
  }
`;
