import { gql } from '@apollo/client';

export const deleteClinician = gql`
  mutation deleteClinician($clinicianId: String!) {
    deleteClinician(clinicianId: $clinicianId) {
      id
      firstName
      lastName
    }
  }
`;
