import styled from 'styled-components';

type CardProps = {
  flex?: number;
  backgroundColor?: string;
  margin?: string;
};

const Card = styled.div<CardProps>`
  position: relative;
  flex: ${({ flex }) => (flex ? flex : 1)};
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor ? backgroundColor : theme.colors.white};
  margin: ${({ margin }) => (margin ? margin : 0)};
  height: 70vh;
  min-height: 465px;
  border-radius: ${({ theme }) => theme.borderRadius};
  filter: drop-shadow(0px 4px 8px rgba(20, 37, 59, 0.08));
`;
export default Card;
