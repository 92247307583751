import { gql } from '@apollo/client';

export const getEmployeesByCompanyId = gql`
  query getEmployeesByCompanyId($companyId: String!) {
    getEmployeesByCompanyId(companyId: $companyId) {
      id
      firstName
      lastName
      sitesOfCareIds
      areasOfPracticeIds
      email
      jobTitle
      userStatus
    }
  }
`;
