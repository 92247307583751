import { gql, OperationVariables, useQuery } from '@apollo/client';

export const getClinicalAttributes = gql`
  query getClinicalAttributes {
    getRoles {
      name {
        en
      }
      id
    }
    getSitesOfCare {
      name {
        en
      }
      id
    }
    getAreasOfPractice {
      name {
        en
      }
      id
      areaOfSpecialisationId
    }
    getAreasOfSpecialisation {
      areaOfPracticeIds
      roleIds
      siteOfCareIds
      id
      name {
        en
      }
    }
  }
`;

export const useGetClinicalAttributes = (options?: OperationVariables) => {
  return useQuery<GetClinicalAttributesType>(getClinicalAttributes, options);
};

export type AreaOfSpecialisationArray = {
  name: { en: string };
  id: string;
  siteOfCareIds: string[];
  roleIds: string[];
  areaOfPracticeIds: string[];
}[];

export type GetClinicalAttributesType = {
  getRoles: { name: { en: string }; id: string }[];
  getSitesOfCare: { name: { en: string }; id: string }[];
  getAreasOfPractice: { name: { en: string }; id: string; areaOfSpecialisationId: string }[];
  getAreasOfSpecialisation: AreaOfSpecialisationArray;
};
