import React, { ComponentType } from 'react';
import { useRouter } from 'next/router';
import { LoggedInUser, useAuth } from 'contexts/AuthProvider/AuthProvider';
import { USER_GROUPS } from 'services/auth';

type ProtectRoutes = 'buAdmin' | 'vTailAdmin' | 'onboarding';

export const withAuthRouteProtection = (
  WrappedComponent: ComponentType,
  protectRoutes: ProtectRoutes,
): ComponentType => {
  // eslint-disable-next-line react/display-name
  return (props: React.PropsWithChildren<unknown>) => {
    const router = useRouter();
    const { user } = useAuth();

    if (user?.userGroup) {
      const { conditional } = getConditionalAndPath(protectRoutes, user);
      if (conditional) {
        return <WrappedComponent {...props} />;
      } else {
        if (typeof window !== 'undefined') {
          router.back();
        }
        return null;
      }
    } else {
      return null;
    }
  };
};

const getConditionalAndPath = (protectRoutes: ProtectRoutes, user: LoggedInUser) => {
  if (protectRoutes === 'buAdmin' && user.isBUAdmin) {
    return { conditional: !user.isVTailAdmin && user.isBUAdmin };
  }

  if (protectRoutes === 'vTailAdmin' && user.isVTailAdmin) {
    return { conditional: user.isVTailAdmin && !user.isBUAdmin };
  }

  if (protectRoutes === 'onboarding' && user.userGroup === USER_GROUPS.CLINICIAN) {
    return {
      conditional:
        !user.isVTailAdmin && !user.isBUAdmin && user.userGroup === USER_GROUPS.CLINICIAN,
    };
  }

  // Default
  return { conditional: false };
};
