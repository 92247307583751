import { gql } from '@apollo/client';

export const getBusinessUnits = gql`
  query getBusinessUnits($companyId: String!) {
    getBusinessUnits(companyId: $companyId) {
      id
      companyId
      companyName
      name
      employeesNumber
    }
  }
`;
