import styled, { keyframes } from 'styled-components';

const ScaleIn = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;
const ScaleOut = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;
const Move = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(16px, 0);
  }
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
`;

export const Dot = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.blue};
  animation-timing-function: cubic-bezier(0, 1, 1, 0);

  &:nth-child(1) {
    left: 0px;
    animation: ${ScaleIn} 0.9s infinite;
  }

  &:nth-child(2) {
    left: 0px;
    animation: ${Move} 0.9s infinite;
  }

  &:nth-child(3) {
    left: 16px;
    animation: ${Move} 0.9s infinite;
  }

  &:nth-child(4) {
    left: 32px;
    animation: ${ScaleOut} 0.9s infinite;
  }
`;
